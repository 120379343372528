.midia > hr {
  width: 60%;
  height: 4px;
  background-color: #000;
  margin: 0 auto;
}

.midia > h3 {
  text-align: center;
  margin: 32px;
}

.midia .content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  width: 100%;
}

.midia .content-center {
  justify-content: center;
}

.midia .content .video {
  width: 45%;
  height: 317px;

  background-color: #c4c4c4;
  border: 0;
}

.midia .content .artigos {
  width: 40%;
  height: 317px;

  position: relative;
}

.midia .content .artigos .arrow--up,
.midia .content .artigos .arrow--down {
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);

  background-color: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0;

  color: #fff;

  font-size: 26px;
}

.midia .content .artigos .arrow--up {
  top: -44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.midia .content .artigos .arrow--down {
  bottom: -44px;
  display: block;
}

.midia .content .artigos ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  scroll-behavior: smooth;

  overflow-y: auto;

  padding: 0;
  padding-right: 10px;
}

.midia .content .artigos ul li {
  width: 100%;
  height: auto;
  margin: 14px 0;

  background-color: rgba(0, 0, 0, 0.15);

  list-style: none;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 12px;
  border-radius: 4px;
}

.midia .content .artigos ul li:first-child {
  margin-top: 0;
}

.midia .content .artigos ul li:last-child {
  margin-bottom: 0;
}

.midia .content .artigos ul li .artigo-info {
  flex: 1;
}

.midia .content .artigos ul li .artigo-info h4 {
  font-size: 18px;
}

.midia .content .artigos ul li .artigo-info p {
  font-size: 13px;
}

.midia .content .artigos ul li .artigo-download a {
  font-size: 16px;
  color: blue;
  text-decoration: none;

  transition: all 0.5s;

  cursor: pointer;
}

.midia .gestao {
  width: 100%;
  display: flex;
  justify-content: center;
}

.midia .deafult-form .input-area input,
.midia .deafult-form .input-area textarea {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #000;
}

.midia .card-gestao {
  background-color: rgb(233, 233, 233);
  border: 4px;
  padding: 20px;
  margin-top: 20px;
  border-radius: 4px;
}

.midia .gestao-article-list .button-add-card {
  margin-top: 20px;
}

@media (max-width: 970px) {
  .midia .content {
    flex-direction: column;
  }

  .midia .content .video,
  .midia .content .artigos {
    width: 100%;
  }

  .midia .content .artigos {
    margin-top: 60px;
  }

  .midia .content .artigos ul li {
    margin-top: 20px;
  }
}

@media (min-width: 450px) {
  .midia .content .artigos ul::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  .midia .content .artigos ul::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  .midia .content .artigos ul::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: #c4c4c4;
  }
}
