.modal-team-member {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000090;
  z-index: 1000;
  top: 0;
  max-width: 100vw;

  -webkit-animation: fadein 0.5s;
  -moz-animation: fadein 0.5s;
  -ms-animation: fadein 0.5s;
  -o-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-team-member .content {
  width: 40vw;
  height: 80vh;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2vh 2vw;
  background-color: #00000090;
}

.modal-team-member .content .header {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background-color: transparent;
}

.modal-team-member .content .header button {
  color: #fff;
  border: 0;
  background-color: transparent;
  font-size: 1.5rem;
  transition: all 0.2s;
}

.modal-team-member .content .header button:active {
  transform: scale(1.5);
}

.modal-team-member .content img {
  width: 26vh;
  border-radius: 50%;
  aspect-ratio: 1;
  margin: 0 auto 40px auto;
}

.modal-team-member .content h5 {
  color: #fff;
  font-size: 4vh;
  text-align: center;
  margin-bottom: 20px;
}
.modal-team-member .content p {
  text-align: justify;
  font-size: 2.5vh;
  max-height: 400px;
  overflow-y: scroll;
  padding-right: 20px;
}

.modal-team-member .content p::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.modal-team-member .content p::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.modal-team-member .content p::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #c4c4c4;
}

@media (max-width: 1400px) {
  .modal-team-member .content {
    width: 60vw;
    height: 80vh;
  }
}

@media (max-width: 1000px) {
  .modal-team-member .content {
    width: 100vw;
    height: 100vh;
  }
}
