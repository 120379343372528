.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #f8f8f8;
}

.login > form {
  width: min(600px, 80vw);

  display: flex;
  flex-direction: column;

  background-color: rgba(0, 0, 0, 0.151);
  padding: 20px;
  border-radius: 10px;
}

.login > form > img {
  border-radius: 4px;
}

.login > form input {
  border-radius: 4px;
  border: 1px solid #000;
  padding: 8px 16px;
  color: #000;
  font-size: 16px;
  width: 100%;
  outline: none;
  background-color: #fff;
}

.login > form > .input-area {
  position: relative;
  margin-top: 8px;
  width: 100%;
}

.login > form > .input-area input {
  margin-top: 20px;
}

.login > form > .input-area label {
  position: absolute;
  left: 8px;
  top: 32px;

  transition: all 0.5s;
  font-size: 14px;
}

.login > form > .input-area input:valid ~ label,
.login > form > .input-area input:focus ~ label {
  top: 0px;
  left: 0px;
  font-size: 12px;
}

.login > form button {
  margin-top: 20px;
  border: 0;
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  padding: 8px 16px;

  display: flex;
  justify-content: center;
}
