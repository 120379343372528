.Loading {
  width: 50px;
  height: 50px;
  border: 5px solid transparent;
  border-top: 5px solid #fff;

  border-radius: 50%;

  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
