.deafult-form {
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-right: 120px;
}

.deafult-form input,
.deafult-form textarea {
  border-radius: 10px;
  border: 2px solid #fff;
  padding: 8px 16px;
  color: #fff;
  font-size: 16px;
  width: 100%;
  outline: none;
  background-color: #000;
}

.deafult-form .input-area {
  position: relative;
  margin-top: 8px;
  width: 100%;
}

.deafult-form .input-area input,
.deafult-form .input-area textarea {
  margin-top: 20px;
}

.deafult-form .input-area label {
  position: absolute;
  left: 8px;
  top: 32px;

  transition: all 0.5s;
  font-size: 14px;
}

.deafult-form .input-area input:not(:placeholder-shown) ~ label,
.deafult-form .input-area textarea:not(:placeholder-shown) ~ label {
  top: 0px;
  left: 0px;
  font-size: 12px;
}
