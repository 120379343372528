.servicos {
  padding-bottom: 0;
  text-align: justify;
}

.servicos h1,
.servicos h2,
.servicos h3,
.servicos h4,
.servicos h5,
.servicos h6 {
  font-size: 1.2rem;
}

.servicos .section-title {
  text-align: center;
  margin-bottom: 32px;
}

.servicos > p {
  line-height: 24px;
  text-align: justify;
}

.servicos .card-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.servicos .card-list .card {
  width: 450px;
  margin: 48px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.servicos .card-list .card p {
  text-indent: 0;
  margin: 0;
}

.servicos .card-list .card ul li {
  list-style: none;
  margin-top: 4px;
}

.servicos .footer {
  margin-top: 32px;
}

.servicos .footer > p {
  margin: 16px 0;
  text-align: justify;
}

@media (max-width: 1530px) {
  .servicos .card-list .card {
    width: 45%;
  }
}

@media (max-width: 850px) {
  .servicos .card-list .card {
    width: 100%;
  }
}
