.edit-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.edit-button button {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
}

form .input-editor {
  margin-top: 20px;
}

form .buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

form .buttons button,
.button-add-card {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 10px;
  background-color: #f8f8f8;
}

.button-add-card {
  margin-left: 0;
}

form .buttons button:active {
  transform: scale(1.1);
}

.card-gestao-list {
  margin-top: 20px;
}

.card-gestao {
  margin: 24px 0;
}

.card-gestao .card-gestao-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.card-gestao .card-gestao-header button {
  margin-left: 20px;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 10px;
  background-color: #f8f8f8;
}

.card-gestao .card-gestao-header button:active {
  transform: scale(1.1);
}
