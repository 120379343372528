.quem-somos h1,
.quem-somos h2,
.quem-somos h3,
.quem-somos h4,
.quem-somos h5,
.quem-somos h6 {
  font-size: 1.2rem;
}

.quem-somos {
  text-align: justify;
}

.quem-somos .section-title {
  text-align: center;
  margin-bottom: 32px;
}

.quem-somos > p {
  text-indent: 50px;
  line-height: 24px;

  text-align: justify;
  margin: 0;
}

.quem-somos .card-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: 40px 0 48px 0;
}

.quem-somos .card-list .card {
  flex: 1;
  min-width: 30%;
  margin: 20px;
}

.quem-somos .card-list .card .card-title > * {
  text-align: center;
  width: 100%;
}

.quem-somos .diferencial > p {
  line-height: 24px;
  text-align: justify;
}

@media (max-width: 820px) {
  .quem-somos .card-list .card:nth-child(3) {
    margin-left: 0;
    margin-top: 10px;
  }

  .quem-somos .card-list .card {
    min-width: 40%;
  }
}

@media (max-width: 556px) {
  .quem-somos .card-list {
    margin-top: 38px;
  }

  .quem-somos .card-list .card {
    margin-left: 0;
    margin-top: 10px;
    min-width: 100%;
    flex: 0;
  }
}
