* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

html,
body {
  width: 100vw;
}

body {
  background-color: #fff;
  color: #000;
  font-family: 'Roboto', sans-serif;
}

section {
  padding: 50px 42px 42px;
  scroll-margin-top: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

section > * {
  max-width: 1500px;
}

section > h3 {
  font-size: 20px;
  font-weight: bold;
}
