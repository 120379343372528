.menu {
  border-top: 1px solid #fff;
  display: flex;
  justify-content: center;

  padding: 10px 0;
  color: #fff;

  position: -webkit-sticky;
  position: sticky;
  top: 0;

  background-color: #000;
  color: #fff;

  z-index: 100;
}

.menu > .menu-button-open {
  position: fixed;
  top: 20px;
  left: 20px;
  display: none;
  color: #fff;
  border: 0;
  outline: 0;
  font-size: 50px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.menu.menu-button-open {
  z-index: 4;
}

.menu > .menu-button-close {
  position: fixed;
  top: 20px;
  right: 20px;
  color: #fff;
  border: 0;
  outline: 0;
  font-size: 50px;
  background: transparent;
}

.menu-opened .menu-button-open {
  transform: translateX(100vw);
}

.menu nav ul {
  display: flex;
  justify-content: center;
  margin: 0;
}

.menu nav ul li {
  list-style: none;
  margin: 0 14px;
}

.menu nav ul li a {
  color: #fff;
}

.menu nav ul li button {
  background-color: transparent;
  border: 0;
  color: #fff;
}

.link {
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  white-space: nowrap;
}

.link::before,
.link::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
}

.link::before {
  content: '';
}

.link {
  font-family: bely-display, sans-serif;
}

.link::before {
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}

.link:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}

.link--active::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}

@media (max-width: 600px) {
  .menu {
    border: 0;

    justify-content: center;
    align-items: center;
    flex-direction: column;

    position: fixed;

    width: 100vw;
    height: 100vh;

    transform: translateX(-100vw);
    transition: all 0.5s;

    z-index: 1;
  }

  .menu-opened {
    transform: translateX(0);
  }

  .menu nav ul {
    flex-direction: column;
  }

  .menu nav ul li {
    margin: 24px 0;
    font-size: 28px;
  }

  .menu > .menu-button-open {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(100vw);
  }
}
