section.home > * {
  max-width: 100vw;
}

section.home {
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 0;
}

.home .carousel {
  display: flex;
  width: 100%;
  height: 600px;
  justify-content: center;
}

.home .carousel img {
  object-fit: cover;
  width: 100%;
  height: 600px;
}

.home .button-edition {
  position: absolute;
  right: 40px;
  top: 20px;
  flex: 0;

  z-index: 2;

  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.home .gestao {
  width: 100%;
  padding: 40px 0;
  max-width: 1500px;
}

.home .gestao .card-gestao-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 4px 0;

  width: 100%;
}

.home .gestao .card-gestao-header > div > button,
.home .gestao .card-gestao-header > div > label {
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  padding: 8px 16px;
  border-radius: 4px;
  margin-left: 8px;

  cursor: pointer;
}

.home .gestao .card-gestao-header > div > button:active,
.home .gestao .card-gestao-header > div > label:active {
  transform: scale(1.1);
}

.home .gestao .gestao-banner-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
}

.home .gestao form .banner-card {
  width: 32.9%;
}

.home .gestao .banner-card:nth-child(3n + 2) {
  margin-left: 8px;
  margin-right: 8px;
}

.home .gestao form input {
  display: none;
}

.home .gestao form .banner-card > label {
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ccc;
  background-position: center;

  width: 100%;
  height: 250px;

  cursor: pointer;

  flex: 1;
}

@media (max-width: 1000px) {
  .home .gestao .gestao-banner-list {
    justify-content: center;
  }

  .home .gestao form .banner-card {
    width: 80%;
  }
}

@media (max-width: 900px) {
  .home .carousel,
  .home .carousel img {
    height: 400px;
  }
}

@media (max-width: 700px) {
  .home .carousel,
  .home .carousel img {
    height: 300px;
  }
}

@media (max-width: 400px) {
  .home .carousel,
  .home .carousel img {
    height: 200px;
  }
}
