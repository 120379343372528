.card {
  border-radius: 25px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  padding: 32px 24px;
}

.card h4 {
  text-align: center;
  margin-bottom: 36px;
  font-size: 20px;
  font-weight: bold;
}

.card p {
  text-align: justify;
  font-size: 16px;
  text-indent: 50px;
  line-height: 22px;
}
