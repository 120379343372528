.equipe {
  background-color: #000;
  color: #fff;
  margin-top: 40px;
  overflow: visible;
}

.equipe h3 {
  text-align: center;
  margin: 32px;
}

.equipe > div:first-child {
  position: relative;
  width: 100%;
}

.equipe .button-edition {
  position: absolute;
  right: 0px;
  top: 20px;
  flex: 0;

  z-index: 2;

  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.equipe .team-list {
  position: relative;
  z-index: 0;
}

.equipe .team-list ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.equipe .team-list ul li {
  list-style: none;
  margin: 32px;
  text-align: center;
  z-index: 20;
}

.flip-card {
  background-color: transparent;
  width: 200px;
  height: 200px;
  perspective: 1000px;
  cursor: pointer;
  transition: all 0.5s;
}

.flip-card-inner {
  position: relative;
  text-align: center;
  transition: all 0.8s;
  transform-style: preserve-3d;
  background-color: green;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-inner {
  -moz-backface-visibility: hidden;
}

.flip-card-back {
  transition: all 1s;
  z-index: 200;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.equipe .team-list ul li img {
  border-radius: 50%;
  height: 153px;
  width: 153px;
}

.equipe .team-list ul li div {
  margin: 8px 0;
}

.equipe .team-list ul li div a {
  color: #fff;
  margin: 0 8px;
  font-size: 1.5rem;

  text-decoration: none;
}

.equipe .team-list ul li h5 {
  margin: 4px 0;
  margin-top: 20px;
  font-size: 1.1rem;

  /* overflow: hidden; */
  /* display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; */
}

.equipe .team-list ul li h6 {
  font-weight: normal;
  font-size: 1rem;

  /* overflow: hidden; */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.equipe .team-list hr {
  margin: 10px;
  height: 2px;
  background-color: red;
  margin-bottom: 300px;
  border: 0;
  opacity: 1;
}

.equipe .team-list .lines {
  z-index: 10;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
}

.equipe .team-list .lines hr.remove {
  background-color: transparent;
}

.equipe .gestao {
  width: 100%;
}

.equipe .gestao .deafult-form {
  width: 100%;
}

.equipe .gestao .deafult-form .member-card {
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 4px;

  width: 48.5%;
  margin-top: 45px;
}

.equipe .gestao .deafult-form .member-card:nth-child(2n + 1) {
  margin-right: 3%;
}

.equipe .gestao .gestao-member-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.equipe .gestao .input-file {
  display: flex;
  justify-content: center;

  width: 100%;
}

.equipe .gestao .input-file input {
  display: none;
}

.equipe .gestao .input-file label {
  border: 1px solid #ccc;

  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ccc;
  background-position: center;

  border-radius: 4px;

  max-width: 50%;
  height: 250px;
  margin-top: 8px;

  cursor: pointer;

  flex: 1;
}

@media (max-width: 3000px) {
  .equipe .team-list .lines .line-left {
    transform: translateX(-25%);
  }

  .equipe .team-list .lines .line-right {
    transform: translateX(25%);
  }
}

@media (max-width: 2000px) {
  .equipe .team-list .lines .line-left {
    transform: translateX(-20%);
  }

  .equipe .team-list .lines .line-right {
    transform: translateX(20%);
  }
}

@media (max-width: 1650px) {
  .equipe .team-list .lines .line-left {
    transform: translateX(-150px);
  }

  .equipe .team-list .lines .line-right {
    transform: translateX(160px);
  }
}

@media (max-width: 750px) {
  .equipe .gestao .deafult-form .member-card {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .equipe .team-list .lines .line-left {
    transform: translateX(-180px);
  }

  .equipe .team-list .lines .line-right {
    transform: translateX(220px);
  }
}
