.contato {
  background-color: #000;
  color: #fff;
  margin-top: 40px;
}

.contato h3 {
  text-align: center;
  margin: 32px;
}

.contato > .form {
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
}

.contato > .form form {
  width: 45%;
  display: flex;
  flex-direction: column;
  margin-right: 120px;
}

.contato > .form > form input,
.contato > .form > form textarea {
  border-radius: 10px;
  border: 2px solid #fff;
  padding: 8px 16px;
  color: #fff;
  font-size: 16px;
  width: 100%;
  outline: none;
  background-color: #000;
}

.contato > .form > form > .input-area {
  position: relative;
  margin-top: 8px;
  width: 100%;
}

.contato > .form > form > .input-area input,
.contato > .form > form > .input-area textarea {
  margin-top: 20px;
}

.contato > .form > form > .input-area label {
  position: absolute;
  left: 8px;
  top: 32px;

  transition: all 0.5s;
  font-size: 14px;
}

.contato > .form > form > .input-area input:not(:placeholder-shown) ~ label,
.contato > .form > form > .input-area textarea:not(:placeholder-shown) ~ label {
  top: 0px;
  left: 0px;
  font-size: 12px;
}

.contato > .form > form > button {
  padding: 8px 16px;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 4px;

  margin-top: 20px;

  font-size: 1.2rem;

  display: flex;
  justify-content: center;
}

.contato > .form > .location {
  width: 45%;
  height: auto;

  border-radius: 10px;
  overflow: hidden;

  margin-top: 28px;
}

.contato > .footer {
  width: 100%;
  margin-top: 97px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.contato > .footer span {
  font-size: 16px;
}

.contato > .footer h6 {
  font-size: 18px;
  font-weight: normal;
}

.contato > .footer > .phone,
.contato > .footer > .address {
  width: 300px;
}

.contato > .footer > .email {
  flex: 1;
  max-width: 380px;
  text-align: center;
  flex-grow: 1;
  overflow-wrap: break-word;
}

.contato > .footer > .email > hr {
  height: 4x;
  background-color: #fff;
  margin: 8px 0;
}

.contato > .copyright {
  margin-top: 90px;
  text-align: center;
}

.contato > .copyright h6 {
  font-weight: normal;
  font-size: 16px;
}

.contato > .gestao {
  width: 100%;

  display: flex;
  justify-content: center;
}

@media (max-width: 1000px) {
  .contato > .footer {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .contato > .footer > .phone,
  .contato > .footer > .address {
    text-align: center;
  }

  .contato > .footer .email {
    margin: 50px 0;
    width: 100%;
  }
}

@media (max-width: 900px) {
  .contato > .form {
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
  }

  .contato > .form form {
    width: 100%;
  }

  .contato > .form .location {
    width: 100%;
    height: 300px;
  }
}
